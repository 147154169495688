import React, { FC, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import FroalaEditor from "react-froala-wysiwyg";

import { SerializedStyles } from "@emotion/react";
import { Label } from "@epignosis_llc/gnosis";
import { useClickAway } from "ahooks";
import ReactFroalaEditor from "froala-editor";
import { isEqual, sortBy } from "lodash";
import PubSub from "pubsub-js";

import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/code_view.min.js";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/js/plugins/line_height.min.js";
import "froala-editor/js/plugins/quote.min.js";
import "froala-editor/js/plugins/save.min.js";
import "froala-editor/js/plugins/quick_insert.min.js";

import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import { EditorStyles } from "@components/FormElements/Editor/styles";

import { insertImageBadLInkMessage } from "@components/FormElements/Editor/helpers";
import { useApplyTranslations, useLoadScript } from "@hooks";
import { useConfigurationStore } from "@stores";
import { i18n } from "@utils/i18n";

import {
  AI_DROPDOWN_SUBSCRIBER,
  DEFAULT_CONFIGURATION,
  DEFAULT_IMAGE_INSERT_BUTTONS,
  FONT_AWESOME_KIT_URL,
  WRITE_ABOUT_SUBSCRIBER,
} from "@components/FormElements/Editor/constants";

import { AIPromptModal } from "@components/AI/AIPromptModal/AIPromptModal";
import { useAIActions } from "@components/FormElements/Editor/AI/AIActions";
import AIDropdownList from "@components/FormElements/Editor/AIDropdownList";
import {
  registerAIFeaturesButton,
  registerInsertSmartTagButton,
  registerUploadImageButton,
} from "@components/FormElements/Editor/customCommands/customButtons";
import { registerInsertImagesDropdown } from "@components/FormElements/Editor/customCommands/customDropdowns";
import { defineEditorCustomIcons } from "@components/FormElements/Editor/customCommands/customIcons";
import {
  registerInsertSmartTagQuickInsertButton,
  registerUploadImageQuickInsertButton,
  registerWriteAboutQuickInsertButton,
} from "@components/FormElements/Editor/customCommands/customQuickInsertButtons";
import {
  CustomDropdown,
  CustomQuickInsertButton,
} from "@components/FormElements/Editor/customCommands/types";
import {
  AIEditorOptions,
  AllAIGenerateTextActions,
  InsertCustomOptions,
  ToolbarButton,
} from "@components/FormElements/Editor/types";
import "@components/FormElements/Editor/config";

export type EditorProps = {
  id: string;
  model: string;
  toolbarButtons: string[] | ToolbarButton;
  placeholderText: string;
  minHeight?: number;
  heightMax?: number;
  label?: string;
  status?: "valid" | "error";
  autofocus?: boolean;
  toolbarInline?: boolean;
  required?: boolean;
  insertImagesOptions?: InsertCustomOptions;
  uploadImageSubscriber?: string;
  uploadImageCloseSubscriber?: string;
  smartTagInsertSubscriber?: string;
  smartTagCloseSubscriber?: string;
  quickInsertEnabled?: boolean;
  aiEditorOptions?: AIEditorOptions;
  onChange?: (html: string) => void;
  onBlur?: () => void;
  onCodeViewToggle?: (isActive: boolean) => void;
  onFocus?: (isFocused: boolean) => void;
  onUploadImageButtonClick?: () => void;
  onInsertSmartTagButtonClick?: () => void;
  onAIStatusChanged?: (isWorking: boolean) => void;
};

const Editor: FC<EditorProps> = ({
  toolbarButtons,
  id,
  placeholderText = "",
  minHeight = 150,
  heightMax,
  label,
  required = false,
  status = "valid",
  model = "",
  autofocus = false,
  toolbarInline = false,
  insertImagesOptions,
  aiEditorOptions,
  uploadImageSubscriber,
  uploadImageCloseSubscriber,
  smartTagInsertSubscriber,
  smartTagCloseSubscriber,
  quickInsertEnabled = false,
  onChange,
  onBlur,
  onCodeViewToggle,
  onFocus,
  onUploadImageButtonClick,
  onInsertSmartTagButtonClick,
  onAIStatusChanged,
}): JSX.Element => {
  const { t } = useApplyTranslations();
  const { userProfileData } = useConfigurationStore();
  const { isLoaded: isFontAwesomeKitLoaded } = useLoadScript(FONT_AWESOME_KIT_URL);

  const editorRef = useRef<HTMLDivElement>(null);
  const editorModelRef = useRef<string>(model);
  const editorInstanceRef = useRef<ReactFroalaEditor | null>(null);
  const editorSnapshotRef = useRef<string | null>(null);
  const aiDropdownRef = useRef<HTMLDivElement | null>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isSelectionInEditor, setIsSelectionInEditor] = useState(true);
  const [isUploadImageOpen, setIsUploadImageOpen] = useState(false);
  const [isInsertSmartTagOpen, setIsInsertSmartTagOpen] = useState(false);
  const [isWriteAboutModalOpen, setIsWriteAboutModalOpen] = useState(false);
  const [showAiDropdown, setShowAiDropdown] = useState<boolean>(false);

  const { can_use_editor_ai_options = false } = userProfileData?.policies?.ai ?? {};
  const hasError = status === "error";
  const hasImageOptions = insertImagesOptions && Object.keys(insertImagesOptions).length > 0;

  // Dynamically show/hide INSERT_IMAGES_DROPDOWN option from insert images buttons
  const imageInsertButtons = !hasImageOptions
    ? DEFAULT_IMAGE_INSERT_BUTTONS
    : [...DEFAULT_IMAGE_INSERT_BUTTONS, CustomDropdown.InsertImageDropdown];

  const aiActions = useAIActions(id, editorInstanceRef, onAIStatusChanged);
  const aiOptions =
    can_use_editor_ai_options && aiEditorOptions ? aiEditorOptions : { aiEnabled: false };
  const { aiEnabled, disabledActions, forceEnableWriteAbout } = aiOptions;

  const isAiPromptModalOpen =
    aiEnabled && isWriteAboutModalOpen && !disabledActions?.includes("completion");
  const registerWriteAboutButton =
    (quickInsertEnabled || forceEnableWriteAbout === true) &&
    aiEnabled &&
    !disabledActions?.includes("completion");

  // If all froala actions in the dropdown are disabled we need to hide some things
  const allAIPermissionsAreDisabled =
    disabledActions && isEqual(sortBy(AllAIGenerateTextActions), sortBy(disabledActions));

  const shouldForceWriteAbout = forceEnableWriteAbout && !disabledActions?.includes("completion");
  const isQuickInsertEnabled = quickInsertEnabled || !!shouldForceWriteAbout;
  const quickInsertButtons =
    !quickInsertEnabled && shouldForceWriteAbout
      ? [CustomQuickInsertButton.WriteAbout]
      : DEFAULT_CONFIGURATION.quickInsertButtons;
  const showAIDropdownList = showAiDropdown && isSelectionInEditor && aiEnabled;

  // Define all custom icons used in custom commands registered in the editor
  defineEditorCustomIcons();

  const closeAiMenu = (): void => setShowAiDropdown(false);

  const handleSelectionChange = (): void => {
    const editor = editorInstanceRef.current;
    const selectionInEditor = editor?.selection?.inEditor();
    setIsSelectionInEditor(selectionInEditor === true);

    const isAiButtonDisabled = aiActions.isSelectionInvalid();
    const aiButton = document.querySelector('.fr-command[data-cmd="aiFeatures"]');

    if (!aiButton) {
      return;
    }

    if (isAiButtonDisabled) {
      aiButton.classList.add("fr-disabled");
      aiButton.setAttribute("disabled", "disabled");
    } else {
      aiButton.classList.remove("fr-disabled");
      aiButton.removeAttribute("disabled");
    }
  };

  if (hasImageOptions) {
    registerInsertImagesDropdown(insertImagesOptions);
  }

  if (uploadImageSubscriber) {
    const uploadImageButtonCallback = function (editor: ReactFroalaEditor): void {
      if (!onUploadImageButtonClick) return;

      // Trigger save selection in order to apply the proper selection markers in editor's html
      editor.selection.save();

      // Take an editor's snapshot, that includes editor's selection info
      editorSnapshotRef.current = editor.snapshot.get();

      onUploadImageButtonClick();
      setIsUploadImageOpen(true);

      // Define what happens once the image is uploaded
      const handleImageUpload = (token: string, imageUrl: string): void => {
        // Restore the previous saved snapshot
        editor.snapshot.restore(editorSnapshotRef.current);

        // Insert the new uploaded image
        editor.image.insert(imageUrl, false, {}, null);

        // Reset ref
        editorSnapshotRef.current = null;
        setIsUploadImageOpen(false);

        // Unsubscribe to ensure this callback isn't called multiple times
        PubSub.unsubscribe(token);
      };

      // Subscribe to all events of the given uploadImageSubscriber
      PubSub.subscribe(uploadImageSubscriber, handleImageUpload);

      if (uploadImageCloseSubscriber) {
        // Define what happens on image upload modal close
        PubSub.subscribe(uploadImageCloseSubscriber, () => setIsUploadImageOpen(false));
      }
    };

    registerUploadImageButton(uploadImageButtonCallback);

    if (quickInsertEnabled) {
      registerUploadImageQuickInsertButton(uploadImageButtonCallback);
    }
  }

  if (smartTagInsertSubscriber) {
    const insertSmartTagsButtonCallback = (editor: ReactFroalaEditor): void => {
      if (!onInsertSmartTagButtonClick) return;

      // Trigger save selection in order to apply the proper selection markers in editor's html
      editor.selection.save();

      // Take an editor's snapshot, that includes editor's selection info
      editorSnapshotRef.current = editor.snapshot.get();

      onInsertSmartTagButtonClick();
      setIsInsertSmartTagOpen(true);

      // Define what happens on smart tag selection
      const handleSmartTagInsert = (token: string, smartTag: string): void => {
        // Restore the previous saved snapshot
        editor.snapshot.restore(editorSnapshotRef.current);

        // Insert the smart tag
        editor.html.insert(smartTag);

        // Reset ref
        editorSnapshotRef.current = null;
        setIsInsertSmartTagOpen(false);

        // Unsubscribe to ensure this callback isn't called multiple times
        PubSub.unsubscribe(token);
      };

      // Subscribe to all events of the given handleSmartTagInsert
      PubSub.subscribe(smartTagInsertSubscriber, handleSmartTagInsert);

      if (smartTagCloseSubscriber) {
        // Define what happens on smart tag modal close
        PubSub.subscribe(smartTagCloseSubscriber, () => setIsInsertSmartTagOpen(false));
      }
    };

    registerInsertSmartTagButton(insertSmartTagsButtonCallback);

    if (quickInsertEnabled) {
      registerInsertSmartTagQuickInsertButton(insertSmartTagsButtonCallback);
    }
  }

  if (registerWriteAboutButton) {
    const aiInsertButtonCallback = (editor: ReactFroalaEditor): void => {
      editor.selection.save();
      setIsWriteAboutModalOpen(true);

      // Define what happens on smart tag selection
      const handleWriteAboutInsert = (token: string, prompt: string): void => {
        aiActions.insert(prompt, editor);

        setIsWriteAboutModalOpen(false);
        // Unsubscribe to ensure this callback isn't called multiple times
        PubSub.unsubscribe(token);
      };

      // Subscribe to all events of the given handleWriteAboutInsert
      PubSub.subscribe(WRITE_ABOUT_SUBSCRIBER, handleWriteAboutInsert);
    };

    registerWriteAboutQuickInsertButton(aiInsertButtonCallback);
  }

  if (aiEnabled && !allAIPermissionsAreDisabled) {
    registerAIFeaturesButton((editor) => PubSub.publish(AI_DROPDOWN_SUBSCRIBER, editor));
  }

  // On click outside fire blur callback
  // Used instead of Froala'a blur event and the reason behind this approach is
  // That image caption is firing blur event as well (probably Froala's bug).
  useClickAway((e) => {
    handleSelectionChange();

    // Not fire blur when editor is not focused
    if (!isFocused) return;

    const target = e.target as HTMLElement;

    // Check if the target is a froala element because,
    // There some editor's pop-ups elements that shouldn't trigger blur
    // Eg. insert image and image pop-up
    const isFroalaElement = Array.from(target.classList).some((className) =>
      className.startsWith("fr"),
    );

    // Not trigger blur when upload image or insert smart tag modal is opened
    if (isUploadImageOpen || isInsertSmartTagOpen) return;

    if (!isFroalaElement) {
      setIsFocused(false);
      onFocus && onFocus(false);
      onBlur && onBlur();
    }
  }, editorRef);

  // Editor handled events
  const events = {
    initialized: function (this: ReactFroalaEditor): void {
      editorInstanceRef.current = this;

      // Set aria-label to the editor, to solve no accessible label issue
      this.el.setAttribute("role", "textbox");
      this.el.setAttribute("aria-label", placeholderText);
    },
    keyup: handleSelectionChange,
    mouseup: handleSelectionChange,
    focus: (): void => {
      handleSelectionChange();
      setIsFocused(true);
      onFocus && onFocus(true);
    },
    "image.error": (error: { code: number }): void => {
      // Bad link error
      if (error.code === 1) {
        const editor = editorRef.current;
        const errorHeading = editor?.querySelector(".fr-image-progress-bar-layer h3");

        if (errorHeading) {
          errorHeading.innerHTML = insertImageBadLInkMessage();
        }
      }
    },
    "commands.mousedown": function (btn: { 0?: HTMLElement }): void {
      let target: HTMLElement | null | undefined = btn[0];

      while (target && target instanceof HTMLElement) {
        if (target.hasAttribute("data-cmd") && target.getAttribute("data-cmd") === "aiFeatures") {
          return;
        }
        target = target.parentElement; // Move up the DOM tree
      }

      closeAiMenu();
    },
    "commands.after": function (cmd: string): void {
      if (cmd === "html") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const isActive = (this as any)?.codeView?.isActive();
        onCodeViewToggle && onCodeViewToggle(isActive);
      }
    },
    "image.inserted": function (): void {
      // Hide loading image popup on image insertion
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this as any)?.popups?.hideAll();
    },
    "toolbar.hide": function (): void {
      closeAiMenu();
    },
  };

  // Final editor's configuration
  const config = {
    ...DEFAULT_CONFIGURATION,
    imageInsertButtons,
    toolbarButtons: toolbarButtons,
    placeholderText: placeholderText,
    heightMin: minHeight,
    heightMax: heightMax,
    toolbarInline: toolbarInline,
    direction: i18n.dir(),
    autofocus: autofocus,
    quickInsertEnabled: isQuickInsertEnabled,
    quickInsertButtons,
    events,
  };

  const handleModelChange = (model: string): void => {
    if (model !== editorModelRef.current) {
      editorModelRef.current = model;
      onChange && onChange(model);
    }
  };

  const handleTextGeneration = (prompt: string): void => {
    PubSub.publish(WRITE_ABOUT_SUBSCRIBER, prompt);
    closeWriteAboutModal();
  };

  const closeWriteAboutModal = (): void => {
    setIsWriteAboutModalOpen(false);
  };

  useEffect(() => {
    const handleToggleAiDropdown = (_: string, editor: ReactFroalaEditor): void => {
      if (editor === editorInstanceRef.current) {
        setShowAiDropdown((show) => !show);
      }
    };

    PubSub.subscribe(AI_DROPDOWN_SUBSCRIBER, handleToggleAiDropdown);

    return () => {
      PubSub.unsubscribe(handleToggleAiDropdown);
      editorInstanceRef.current = null;
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === "Escape") {
        aiActions.cancel();
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [aiActions]);

  useEffect(() => {
    const aiButton = document.querySelector('.fr-command[data-cmd="aiFeatures"]');
    if (aiButton) {
      if (showAiDropdown) {
        // Append fr-ai-btn-active class
        aiButton.classList.add("fr-ai-btn-active");
      } else {
        // Remove fr-ai-btn-active class
        aiButton.classList.remove("fr-ai-btn-active");
      }
    }
  }, [showAiDropdown]);

  useEffect(() => {
    const toolbars = document.querySelectorAll<HTMLElement>(".fr-toolbar");

    if (isAiPromptModalOpen) {
      toolbars.forEach((toolbar) => {
        toolbar.style.display = "none";
      });
    }
  }, [isAiPromptModalOpen]);

  useEffect(() => {
    if (editorModelRef.current !== model) {
      editorModelRef.current = model;
    }
  }, [model]);

  return (
    <>
      <div
        id={id}
        ref={editorRef}
        css={(theme): SerializedStyles => EditorStyles(theme, { required, isFocused, hasError })}
      >
        {label && <Label>{label}</Label>}

        {isFontAwesomeKitLoaded && (
          <FroalaEditor
            tag="textarea"
            config={config}
            model={editorModelRef.current}
            onModelChange={handleModelChange}
          />
        )}
      </div>

      <AIPromptModal
        id={id}
        isOpen={isAiPromptModalOpen}
        onClose={closeWriteAboutModal}
        header={t("ai.writeAbout")}
        onGenerate={handleTextGeneration}
      />

      {showAIDropdownList &&
        createPortal(
          <AIDropdownList
            dropdownRef={aiDropdownRef}
            aiActions={aiActions}
            aiOptions={aiOptions}
            close={closeAiMenu}
          />,
          document.body,
        )}
    </>
  );
};

export default React.memo(Editor);
